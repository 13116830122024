import React, { useState } from "react"

const Context = React.createContext()

function ContextProvider({children}) {
  const [theme, setTheme] = useState('black')
  const [useTheme, setUseTheme] = useState(
    {grad: "linear-gradient(-45deg,#D9A566, #A66F3F)",
    bg: `linear-gradient(-45deg, #0F1010, #171819, #1A1B1D, #0F1010, #171819, #1A1B1D, #0F1010, #171819, #1A1B1D, #0F1010, #171819, #1A1B1D)`,
    thigh: `#E8E8E8`,
    ttheme: `#A66F3F`,
  tdetail: `#A66F3F`,
  ttitle: `#E8E8E8`,
  tnav: `#A66F3F`,
  showgap: `#222`}
  )

  function themeCreator (value) {
    if (value === "black"){
      setUseTheme(
      {
      grad: `linear-gradient(-45deg,#D9A566, #A66F3F)`,
      bg: `linear-gradient(-45deg, #0F1010, #171819, #1A1B1D, #0F1010, #171819, #1A1B1D, #0F1010, #171819, #1A1B1D, #0F1010, #171819, #1A1B1D)`,
      thigh: `#E8E8E8`,
      ttheme:`#A66F3F`,
      tdetail: `#A66F3F`,
      ttitle: `#E8E8E8`,
      tnav: `#A66F3F`,
      showgap: `#222`}
      )
    }
    else if (value === "grey"){
      setUseTheme(
        {
        grad: `linear-gradient(-45deg, #E39F9F, #5E4242)`,
        bg: `#E8E8E8`,
        thigh: `#222D50`,
        ttheme: `#05BB75`,
      tdetail: `#05BB75`,
      ttitle: `#222D50`,
      tnav: `#222D50`,
      showgap: `#05BB75`}
      )
    }
    else if (value === "teal"){
      setUseTheme(
        {
        grad: `#69908C`,
        bg: ` #365651`,
        thigh: `#6F7568`,
        ttheme: `#9FAEB0`,
      tdetail: `#6F7568`,
      ttitle: `#9FAEB0`,
      tnav: `#6F7568`,
      showgap: `#6F7568`}
      )
    }
    else if (value === "pastal"){
      setUseTheme(
        {
        grad: `linear-gradient(90deg, #94EFE5, #83D4BC, #68CEAF, #85D8C0, #4EC4A8)`,
        bg: `linear-gradient(-45deg, #EBE8E3, #E6E2DD, #CCC3BF, #C8BCB7, #B1A39F)`,
        thigh: `#FCE585`,
        ttheme: `#006260`,
      tdetail: `#006260`,
      ttitle: `#0D2B3A`,
      tnav: `#FCE585`,
      showgap: `#AD30A2`}
      )
    }
    else if (value === "purple"){
      setUseTheme(
        {
        grad: `linear-gradient(90deg, #DDDADD, #E2DFE2, #E2E0E3, #ECEAEC, #EFEEF0)`,
        bg: `linear-gradient(-45deg, #999CA7, #9498A5, #9495A3, #BDC2D9, #BCBDCC, #FBF6EE)`,
        thigh: `#FFFFFF`,
        ttheme: `#AD30A2`,
        tdetail: `#AD30A2`,
        ttitle: `#AD30A2`,
        tnav: `#FFFFFF`,
        showgap: `#AD30A2`}
      )
    }
  }



    return (
        <Context.Provider value={{
            theme,
            setTheme,
            useTheme,
            themeCreator
        }}>
            {children}
        </Context.Provider>
    )
}

export {ContextProvider, Context}
